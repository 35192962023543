/* UTILITY */



body {
    font-family: 'IBM Plex Sans Arabic', sans-serif;
    background-color: #f8f8f8;
    color: rgb(37, 161, 222);
    margin: 0;
    padding: 0;
    box-sizing:border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    
    
}


@media only screen and (max-width:768px)  {

    .smallBegone {
        display: none !important;
    }


    .wideRow {
        width: 100%;
    }

    #smallRow  {
        height:80%;  
    }

    .smallDarken {
        color: rgb(53, 53, 53)
    }

    .smallChange {
        text-align: center;
        margin: auto;
    }

    .smallBackground {
        background-color:rgb(53, 53, 53);
    }

    .keepAway {
        margin-bottom: 250px;
    }
    
    .smallCurrentProject {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .currentProjLeft {
        
        justify-content: center;
    }
    /* .singleProjRow {
        margin-bottom: 100px;
    } */
    .skillColumn {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;

    }
    #smallNoMargin {
        margin: 0px;
    }

}



/* TINY CONTACT  */


/* TEST */

li {
    list-style: none;
}
.main-contain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}
.makeWhite {
    color:#f8f8f8;
}


.darkBlue {
    color: rgb(27, 101, 174);
}
.title-subtitle-div {
    border-bottom:rgb(139, 139, 139) solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
}
.headerRow {
    display: flex; 
    align-items: center;
    justify-content: center;
}
.headingLabel {
    font-weight:bold;
}

.logoBanner {
    width: 90%;
}

#noMargin {
    margin: 0px;
}

#portfolio-link {
    margin: 5px;
    text-decoration: none;
    color: rgb(27, 101, 174);
    font-family:'sen', sans-serif;
    font-weight:bold;
}
#portfolio-link:hover {
    color: rgb(37, 161, 222);
    background-color:rgb(53, 53, 53);
    border-radius: 15px;
    padding: 3px;
    
}

#fancyText {
    margin: 5px;
    text-decoration: none;
    color: rgb(27, 101, 174);
    font-family:'sen', sans-serif;
    font-weight:bold;
}

/* BANNER END */
/* LEFT SIDE ABOUT */

.leftSideWrapper {
    display:flex;
    flex-direction: column;
    margin-left: 20px;
    padding: 15px;
  
    
}

.myName {
    font-weight: bold; 
    color: rgb(27, 101, 174);
}
.description {
    height: 60px;
    overflow:hidden;
 
}
.descriptionWrapper {
    height:100%;
    animation: move 10s ease-in-out infinite;
}

@keyframes move {
    25%{ transform: translateY(-70px); }
    50%{ transform: translateY(-140px); }
    75%{ transform: translateY(-210px); }
    100%{ transform: translateY(-280px); }
}
.descriptor {
    height: 60px;
    font-size: 40px;
    font-family: 'Sen', sans-serif;
    color: rgb(53, 53, 53);
    display: flex;
    align-items: center;
    margin: 5px;


}

.aboutMe p {
    font-size:25px;
    margin-top: 10px;

}
.aboutRightColumn {
    display: flex;
    align-items: center;
    flex-direction:column;
}
/* 
RIGHT SIDE  */
.rightSide {
    margin: 0;
    padding: 0;
    position: relative;
}
.aboutRight {
    
    display: flex;
    align-items: center;
    justify-content: center;
}



.michaelscott {
    object-fit:contain;
    display: block;
    width: 70%;
    transform: rotate(15deg);
    text-align: center;
    border: rgb(53, 53, 53);
    box-shadow: 10px 5px 5px rgb(53, 53, 53);
    margin-top: 40%;
}
.michaelscottSmall {
    object-fit:contain;
    max-width: 275px;
    transform: rotate(15deg);
    text-align: center;
    border: rgb(53, 53, 53);
    box-shadow: 10px 5px 5px rgb(53, 53, 53);
}


.aboutRowBG {
    /* clip-path: polygon(100% 0, 100% 33%, 0 100%, 0 67%); */
    object-fit:contain;
    clip-path: polygon(100% 25%, 100% 92%, 0 100%, 0 60%);
    object-fit:contain;
    background-color:rgb(27, 101, 174);
    position: absolute;
    left:0;
    height: 100%;
    width: 100%;
    z-index: 0;
}




.projTitle {
    position:relative;
}

.aboutLeftCol {
    display: flex;
    flex-direction: column;
    justify-content:space-between;

}


.leftCol {
    background-color: #f8f8f8;
    height: 600px;
    padding: 0px;
}
/* 
PROJECT SECTION  */

.centerContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.aboutInfo {
    color:#f8f8f8;
    font-family: 'Sen', sans-serif;
    font-weight:400

}
/* PROJECT HEADER  */
.projectRow{
    position: relative;
}
.projRowBG {
    clip-path: polygon(55% 18%, 60% 57%, 48% 89%, 45% 57%);
    background-color:rgb(27, 101, 174);
    position: absolute;
    top:0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
}

.projectsHeaderRow {
    height: 200px;
    margin-top: 30px;
}

.projectsHeader{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.projectsHeader h5, h4, h2, h3, h1{
    font-family: 'Sen', sans-serif;
    font-weight: bold;
    z-index: 3;
}





.projectImg {
    width: auto;
    height: 65%;
    border: rgb(53, 53, 53) solid 3px;
    border-radius: 20%;
    z-index: 1;
    box-shadow: 5px 5px 5px rgb(53, 53, 53);
}

.rotateLeft {
    transform: rotate(15deg);
}
.rotateRight {
    transform: rotate(15deg);
}

#front {
    z-index: 5;
}

/* PROJECT SECTION BACKGROUNDS */
.projLeftBG {
    clip-path: circle(15.9% at 22% 32%);
    background-color:rgb(53, 53, 53);
    position: absolute;
    top:0;
    right: 0;
    height: 100%;
    width: 100%;

    
}
.projRightBG {
    clip-path: circle(8.0% at 59% 11%);
    background-color:rgb(53, 53, 53);
    position: absolute;
    top:0;
    right: 0;
    height: 100%;
    width: 100%;
}
.projLeftBG2 {
    clip-path: circle(11.9% at 67% 49%);
    background-color:rgb(53, 53, 53);
    position: absolute;
    top:0;
    right: 0;
    height: 100%;
    width: 100%;
}

.projRightBG2 {
    clip-path: circle(6.4% at 11% 25%);
    background-color:rgb(53, 53, 53);
    position: absolute;
    top:0;
    right: 0;
    height: 100%;
    width: 100%;
}

.projCurrentBG {
    clip-path: polygon(0% 0%, 100% 7%, 100% 100%, 0 93%);
    background-color:rgb(37, 161, 222);
    position: absolute;
    top:0;
    right: 0;
    height: 100%;
    width: 100%;
}


.leftProject {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}


.rightProject {
    font-size: 25px;
    font-family: 'Sen', sans-serif;
    color:rgb(37, 161, 222);
    position: relative;      
}

.projectLinks {
    display: flex;
}

#project-link {
    margin: 5px 10px;
    text-decoration: none;
    color: rgb(27, 101, 174);
    font-family:'sen', sans-serif;
    font-size: 1.6rem;
    font-weight: bold;
    padding: 5px;

}

#project-link:hover {
    color:rgb(37, 161, 222);
    background-color:rgb(53, 53, 53);
    border-radius: 15px;
}
.currentProjLeft {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

}

#currentProject {
    color: rgb(53, 53, 53);
}
/* CURRENT PROJECT */
.currentProjectContainer {
    position: relative;
    min-height: 750px;
}
.rightCurrentProject {
    font-size: 22px;
    font-family: 'Sen', sans-serif;
    position: relative;
}

.currentProjectImg {
    height: 65%;
    border: rgb(53, 53, 53) solid 3px;
    border-radius: 20%;
    z-index: 1;
    box-shadow: 5px 5px 5px rgb(53, 53, 53);
}

.projectImgSmall {
    height: 175px;
    width: 225px;
    border: rgb(53, 53, 53) solid 3px;
    border-radius: 20%;
    z-index: 1;
    box-shadow: 5px 5px 5px rgb(53, 53, 53);
}

/* SKILLS SECTION */

.sarcasmImg {
    transform: rotate(15deg);
    border: rgb(20, 20, 20) solid 2px;
    max-width: 200px;
    margin-bottom: 30px;
    box-shadow: 10px 5px 5px rgb(20,20,20);
}

.bottomLine {
    border-bottom:green solid 3px;
}
.topLine {
    border-bottom:green solid 3px;
}

.skillColumn {
    color:rgb(37, 161, 222);
    margin-bottom: 10px;
}
.skillColumn h2{
    font-weight: bold;
    
    font-family: 'Sen', sans-serif;
    padding: 10px;
    
}
.skillsHeader{
    display: flex;
    align-items: center;
}
.skillsHeader h4 {
    font-family: 'Sen', sans-serif;
    font-weight: bold;
    z-index: 3;
}
.buffer {
    height: 75px;
    width: auto;
}
.skillsBG {
    clip-path: polygon(0 7%, 100% 0%, 100% 93%, 0 100%);
    background-color: rgb(53, 53, 53);
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit:contain;
}

.skillsItem {
    margin: 5px 0px 0px 30px;;
    color: #f8f8f8;
}
.skillsImageWrapper {
    display: flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items: center;
}
.relative {
    position: relative;
}

.makeWhite {
    color: #f8f8f8;
}
.languageImg {
    max-width: 150px;
    border-radius: 15px;
    padding: 10px;
    margin: 5px;
}
.languageImageSmall {
    max-width: 75px;
    border-radius: 15px;
    padding: 10px;
    margin: 10px;
}


.tuxedoImg {
    max-width: 45px;
    transform: rotate(-5deg);
    border: rgb(53, 53, 53);
}

/* CONTACT FORM CSS */
.formRow {
    max-width: 650px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactHeader h4 {
    font-family: 'Sen', sans-serif;
    font-weight: bold;
    z-index: 3;
}
.contactInfoSmall {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profSkillsBG {
    clip-path: polygon(0% 0%, 100% 7%, 100% 100%, 0 93%);
    background-color:rgb(27, 101, 174);
    position: absolute;
    top:0;
    right: 0;
    height: 100%;
    width: 100%;

}

.profSkillsItem {
    padding:5px;
}

.skillsWrapper {
    color: rgb(53, 53, 53);
    font-size:x-large;
   
}
.line {
    background-color:rgb(53, 53, 53);
    height:3px;
    z-index: 3;
}
.roundMe {
    border-radius: 10px;
}

.contactHeader {
    display: flex;
    align-items:center;
    justify-content:center;
}
.meWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.meImage {
    width: 500px;
    height: auto;
    background-color: #f8f8f8;
    background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%231b65ae' fill-opacity='0.51' fill-rule='evenodd'/%3E%3C/svg%3E");
}
.meImageSmall {
    width: 200px;
    height: auto;
    background-color: #f8f8f8;
    background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%231b65ae' fill-opacity='0.51' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.clip-hex {
    clip-path: polygon(9% 14%, 86% 6%, 84% 73%, 24% 94%);

}
  

/* TEST */

